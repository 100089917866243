import React from "react";
import logo from "../assets/images/logo.png";

const Header = ({ showPage }) => {
  return (
    <div id="header">
                      {showPage === "welcome" && (

      <div className="navigation-wrap start-header start-style">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="navbar navbar-expand-md navbar-light">
                  <a className="navbar-brand" href="/" target="_blank">
                    <img src={logo} alt="" />
                  </a>                 
                   
              </nav>
            </div>
          </div>
        </div>
      </div>
                      )}

    </div>
  );
};

export default Header;









