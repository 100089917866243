import React, { useState } from "react";
import HomePageCard from "./HomePageCard";
import Slider from "../UI/Slider";
import Header from "../UI/Header";
import Footer from "../UI/Footer";
import Slide from "../UI/Slide";
import slider0 from "../assets/images/slider0.png";
import slider1 from "../assets/images/slider1.jpg";
import slider2 from "../assets/images/slider2.png";
import slider3 from "../assets/images/slider3.jpg";

const Homepage = () => {
  const [showPage, setShowPage] = useState("home");

  const slideData = [
    {
      id: "slider0",
      desc:"The Managerial Development Program app is now live. Scan the QR code shown above to download the mobile app. <br />iOS users please check out an email with the subject line \"<b>Supersonic iOS Mobile app is here | Download now!\"</b> for a unique link to download the app.",
      src: slider0,
      sliderBg:
        "none",
    },
    {
      id: "slider1",
      title: "‘PROPEL’ PROGRAM STRUCTURE",
      desc: "This program consists of multiple courses wherein each course consists of multiple modules<span class='showMobile'>.</span> <br />Courses to be completed in a <b>sequential fashion</b> in a time-bound setting<span class='showMobile'>.</span> <br />Learn from <b>real-life-situation based customized courses</b> interspersed with <br />curated content from Digital libraries across the Globe<span class='showMobile'>.</span>",
      src: slider1,
      sliderBg:
        "linear-gradient(130.53deg, rgba(0, 0, 0, 0.55) 30.31%, rgba(0, 0, 0, 0.35) 105.88%)",
    },
    {
      id: "slider2",
      title: "ASSESSMENT & CERTIFICATION",
      desc:"<b>‘Multiple Choice Question’</b> assessment & case study to gauge your preparedness for real-life situation management<span class='showMobile'>.</span> <br/> <b>Successful completion</b> of the courses will give you an extra edge to move to a <b>Managerial role</b><span class='showMobile'>.</span> <br/> Get <b>Certification</b> on completion of the course & enhance your learning portfolio",
      src: slider2,
      sliderBg:
        "linear-gradient(110.98deg, rgba(0, 0, 0, 0.55) 47.76%, rgba(0, 0, 0, 0.35) 93.68%)",
    },
    {
      id: "slider3",
      src: slider3,
      sliderBg:
        "none",   
    },
    
  ];

  return (
       <div className={`slideWrapper ${showPage === "home" ? "bgImg" : ""}`}>
      <Header showPage={showPage} />

      {showPage === "home" ? (
        <HomePageCard showPage={showPage} setShowPage={setShowPage} />
      ) : (
        <Slider sliderLength={slideData?.length || 0} setShowPage={setShowPage}>
          {slideData.map((value, index) => {
            return <Slide value={value} index={index} key={index} />;
          })}
        </Slider>
      )}
      <Footer showPage={showPage} />
    </div>
  );
};

export default Homepage;
