import React from 'react';
import facebookIcon from '../assets/images/facebookIcon.svg';
import linkedinIcon from '../assets/images/linkedinIcon.svg';
import twitterIcon from '../assets/images/twitterIcon.svg';
import AppStore from '../assets/images/appStore.png';
import PlayStore from '../assets/images/playStore.png';
import PoweredBy from '../assets/images/poweredBy.png';

const Footer = ({ showPage }) => {
  return (
    <>
      <footer className="Footer" id="footer">
       {/*  <div className="container">
          <hr className="MainFooter__divider" />
          <div className="row">
            <div className="col">
              <div className="MainFooter__border">
                <div className="row MainFooter">
                  <div className="col-md-4 MainFooter__info mb-4">
                    <div className="MainFooter__heading">INFO</div>
                    <div className="Info__content">
                      <ul className='nav1'>
                        <li><a href="/">ICOG</a></li>
                        <li><a href="/" > Blog </a></li>
                       <li> <a href="/"> News</a></li>
                       <li><a href="/">Support</a></li>
                      </ul>
                       
                      <ul className='nav2'>
                        <li><a href="/">Careers</a></li>
                        <li> <a href="/">Terms of services</a></li>
                        <li> <a href="/">Privacy Policies</a></li>
                      </ul>
                        
                    </div>
                  </div>
                  <div className="col-md-4 MainFooter__download">
                    <div className="MainFooter__heading">DOWNLOAD THE APP</div>
                    <div className="Download__icons d-flex">
                      <button className="btn Download__button">
                        <img src={AppStore} alt="AppStoreImg"></img>
                      </button>
                      <button className="btn Download__button">
                        <img src={PlayStore} alt="PlayStoreImg"></img>
                      </button>
                    </div>
                  </div>
                  <div className="col-md-4 MainFooter__poweredBy">
                    <div className="PoweredBy__info">
                      <img src={PoweredBy} alt="PoweredByImg"></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
       
          <div className="SubFooter">
            <div className="container ">
              <div className="row">
                <div className="col">
                  <div className="SubFooter__container">
                    <span className="SubFooter__branding">
                      ICOG 2022 All Rights Reserved
                    </span>
                    <div className="SubFooter__connect">
                    <div className="PoweredBy__info">
                      <img src={PoweredBy} alt="PoweredByImg"></img>
                    </div>
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        
      </footer>
    </>
  );
};

export default Footer;
