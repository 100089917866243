import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const Slider = (props) => {
  return (
    <Carousel
      ariaLabel="carousel"
      autoPlay={false}
      infiniteLoop={false}
      showArrows={true}
      showIndicators={false}
      dynamicHeight={true}
      renderArrowPrev={(onClickHandler, hasPrev) => {
        return (
          <>
            <button
              type="button"
              onClick={() => {
                onClickHandler();

                if (!hasPrev) {
                  props.setShowPage("home");
                }
              }}
              className="control-arrow control-prev"
            ></button>
          </>
        );
      }}
      renderArrowNext={(onClickHandler, hasNext) => {
        return (
          <button
            type="button"
            onClick={() => {
              onClickHandler();
              if (!hasNext) {
                document.location = "/learning/";
              }
            }}
            className="control-arrow control-next"
          ></button>
        );
      }}
    >
      {props.children}
    </Carousel>
  );
};

export default Slider;
