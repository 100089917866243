import React from 'react';
import styled from 'styled-components';

const carouselBg = {
  mixBlendModel: 'normal',
};

const ItemTextCenter = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  // justify-content: center !important;
  color: #fff !important;
  z-index: 10;
  position: absolute;
  left: 0;
  right: 0;
  marginTop : 30;
`;

const Slide = ({ value, index }) => {
  return (
    <>
      <div
        className={`sliderHeight sm-bgPosition ${value.id === 'slider3' ? 'sliderHeight--slider2' : value.id === 'slider0' ? 'sliderHeight--slider0' : ''}`}
        key={index}
        style={{
          ...carouselBg,
          backgroundImage: `url(${value.src})`,
        }}
      >
        {(value.sliderBg && value.id !== 'slider3') && (
          <div
            key={index}
            className="slideOverlay"
            style={{ backgroundImage: `${value.sliderBg}` }}
          >
          </div>
        )}
        <ItemTextCenter className='item_container'>
          <h2 className="text-center">
            <b>{value.title}</b>
          </h2>

      <p className="text-center slider__content sm-px-2" dangerouslySetInnerHTML={{ __html: value.desc}} ></p>
          <div className={`slider__content--action ${value.id === 'slider2' ? 'action--slider2' : ''}`}>
          <a
            rel="noreferrer"
            href="/learning/"
            className="btn btn-warning active"
            role="button"
            target="_self"
            aria-pressed="true"
          >
            <b>Continue to Login</b>
          </a>
          </div>
        </ItemTextCenter>
      </div>
    </>
  );
};

export default Slide;
