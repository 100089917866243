import React from "react";
// import Video from '../UI/Video';
// import videoFile from '../assets/video/video.mp4';
import welcomeImg from "../assets/images/home.png";
import logo from '../assets/images/logo-home.png'

const HomePageCard = ({ setShowPage, showPage }) => {
  // const playURL = videoFile;
  // const videoJsOptions = {
  //   // lookup the options in the docs for more options
  //   autoplay: false,
  //   controls: true,
  //   responsive: true,
  //   fluid: true,
  //   sources: [{ src: playURL, type: 'video/mp4' }],
  // };

  return (
    <>
      <div className="card-group">
        <div className="homeBack"> </div>
        <div className="container mx-auto d-flex">
          <div className="card homepage-card-new border-0">
            <div className="card-body-new">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-12 d-flex flex-column h-100">
                <img src = {logo} alt = 'logo'/>
                      <a
                        rel="noreferrer"
                        href="/learning/"
                        className="btn active loginButton"
                        role="button"
                        target="_self"
                        aria-pressed="true"
                      >
                        <b>Continue to Login</b>
                      </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePageCard;
